export default () => {
    return `
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#348037"/>
            <path d="M22.0033 26.0001H12.5548C11.6877 26.0001 11 25.2526 11 24.3556V11.6479C11 10.7509 11.6877 10.0034 12.5548 10.0034H22.0033C22.8704 10.0034 23.5581 10.7509 23.5581 11.6479V24.3556C23.5581 25.2526 22.8405 26.0001 22.0033 26.0001ZM12.5548 11.6479L12.6146 24.3855L21.9136 24.4154V11.6778L21.0166 11.6479H12.5548Z" fill="white"/>
            <path d="M15.5156 22.7109C15.3362 22.7109 15.1269 22.6511 14.9774 22.5016C14.6485 22.2026 14.6186 21.6943 14.9176 21.3654L17.2498 18.7341H15.7249C15.4259 18.7341 15.1269 18.5547 15.0073 18.2856C14.8877 18.0165 14.9176 17.6577 15.1269 17.4185L18.4458 13.3521C18.7149 12.9933 19.2232 12.9634 19.582 13.2325C19.9409 13.5016 19.9708 14.0398 19.7016 14.3687L17.4591 17.0896H19.0737C19.4026 17.0896 19.6717 17.269 19.8213 17.568C19.9409 17.867 19.911 18.1959 19.6717 18.4351L16.1136 22.4418C15.9641 22.6212 15.7249 22.7109 15.5156 22.7109Z" fill="white"/>
            <path d="M18.7155 9.61462H15.8151C15.3666 9.61462 15.0078 9.25581 15.0078 8.80731C15.0078 8.3588 15.3666 8 15.8151 8H18.7155C19.164 8 19.5228 8.3588 19.5228 8.80731C19.5228 9.25581 19.164 9.61462 18.7155 9.61462Z" fill="white"/>
        </svg>
    `;
}

export default () => {
    return `
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#9D5400"/>
            <path d="M26.5021 20.5545H9.49594V18.7512C9.49594 17.0184 10.014 15.3495 10.9951 13.9275C11.9518 12.5387 13.2834 11.476 14.8443 10.8521L15.6489 10.5303L16.2596 15.9205L14.9457 16.0704L14.5378 12.4593C12.2539 13.7115 10.8209 16.0946 10.8209 18.7534V19.234H25.1816V18.7534C25.1816 16.0969 23.7486 13.7115 21.4646 12.4593L21.0568 16.0704L19.7429 15.9205L20.3535 10.5303L21.1582 10.8521C22.719 11.476 24.0484 12.5387 25.0074 13.9275C25.9884 15.3495 26.5065 17.0162 26.5065 18.7512V20.5545H26.5021Z" fill="white"/>
            <path d="M21.0563 16.0705L19.7423 15.9206L20.2781 11.1719C20.2957 11.0154 20.2229 10.9052 20.1767 10.8544C20.1304 10.8037 20.0311 10.7178 19.8724 10.7178H16.1268C15.9681 10.7178 15.8689 10.8037 15.8226 10.8544C15.7763 10.9052 15.7035 11.0132 15.7212 11.1719L16.2591 15.9206L14.9452 16.0705L14.4073 11.3218C14.3521 10.8324 14.5087 10.343 14.8349 9.97482C15.1634 9.60886 15.633 9.39722 16.1246 9.39722H19.8746C20.3662 9.39722 20.838 9.60665 21.1643 9.97482C21.4928 10.3408 21.6493 10.8324 21.592 11.3218L21.0563 16.0705Z" fill="white"/>
            <path d="M26.3157 22.6027H9.6843C8.75397 22.6027 8 21.8466 8 20.9184C8 19.9903 8.75617 19.2341 9.6843 19.2341H26.3157C27.246 19.2341 28 19.9903 28 20.9184C28 21.8466 27.2438 22.6027 26.3157 22.6027ZM9.6843 20.5547C9.48369 20.5547 9.32275 20.7178 9.32275 20.9162C9.32275 21.1146 9.48589 21.2778 9.6843 21.2778H26.3157C26.5163 21.2778 26.6772 21.1146 26.6772 20.9162C26.6772 20.7178 26.5141 20.5547 26.3157 20.5547H9.6843Z" fill="white"/>
        </svg>
    `
}

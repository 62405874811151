export default () => {
    return `
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5Z" fill="#721B37"/>
        <circle cx="17.5" cy="17.5" r="8.74" stroke="white" stroke-width="1.52"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0474 17.1599C20.0474 17.1599 19.9856 17.1682 19.9591 17.1682C19.9326 17.1682 19.9061 17.1849 19.8795 17.2016C19.853 17.2183 19.8354 17.2433 19.8177 17.2599C19.5703 17.6602 19.0314 17.7936 18.6073 17.5684C18.192 17.3433 18.0506 16.8347 18.2892 16.4345C18.4217 16.2177 18.5984 16.026 18.8105 15.8759C19.0225 15.7258 19.2611 15.6174 19.5173 15.5507C19.7735 15.5007 20.0474 15.484 20.3036 15.5174C20.5687 15.5507 20.8161 15.6424 21.0458 15.7675C21.2755 15.9009 21.4699 16.0676 21.6201 16.2761C21.7791 16.4762 21.8852 16.7097 21.947 16.9515C22.0089 17.1932 22.0089 17.4517 21.9647 17.6935C21.9205 17.9436 21.8233 18.1771 21.682 18.3855C21.6555 18.4272 21.6201 18.4689 21.5848 18.5023L20.9486 19.1359H21.1165C21.6024 19.1359 22 19.5111 22 19.9697C22 20.4283 21.6024 20.8035 21.1165 20.8035H18.9077C18.5542 20.8035 18.2362 20.6034 18.0948 20.3032C17.9534 19.9947 18.0241 19.6446 18.2627 19.4028L20.2153 17.435L20.2241 17.4017C20.2241 17.3767 20.2241 17.3433 20.2241 17.3183C20.2241 17.2933 20.2065 17.2683 20.1888 17.2433C20.1711 17.2183 20.1534 17.2016 20.1269 17.1849C20.1004 17.1682 20.0739 17.1599 20.0474 17.1599Z" fill="white" stroke="#721B37" stroke-width="0.5"/>
        <path d="M14.4062 16.3593V17.6408H16.3621V16.3593C16.3621 16.1762 16.4028 16.0389 16.4842 15.9474C16.5676 15.8558 16.6764 15.8101 16.8107 15.8101C16.947 15.8101 17.0568 15.8558 17.1402 15.9474C17.2257 16.0369 17.2684 16.1742 17.2684 16.3593V19.8836C17.2684 20.0687 17.2257 20.207 17.1402 20.2985C17.0548 20.3901 16.9449 20.4359 16.8107 20.4359C16.6744 20.4359 16.5656 20.3901 16.4842 20.2985C16.4028 20.205 16.3621 20.0666 16.3621 19.8836V18.3793H14.4062V19.8836C14.4062 20.0687 14.3635 20.207 14.2781 20.2985C14.1926 20.3901 14.0828 20.4359 13.9485 20.4359C13.8123 20.4359 13.7034 20.3901 13.6221 20.2985C13.5407 20.205 13.5 20.0666 13.5 19.8836V16.3593C13.5 16.1762 13.5397 16.0389 13.619 15.9474C13.7004 15.8558 13.8102 15.8101 13.9485 15.8101C14.0848 15.8101 14.1947 15.8558 14.2781 15.9474C14.3635 16.0369 14.4062 16.1742 14.4062 16.3593Z" fill="white" stroke="white" stroke-width="0.312455"/>
        <path d="M11.8008 15.0347C12.5237 13.2273 14.6926 11.4199 16.5 11.4199" stroke="white" stroke-width="1.44591" stroke-linecap="round"/>
    </svg>
    `;
}

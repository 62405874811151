import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppFixedHeader from '../../containers/AppFixedHeader';
import Divider from '../Divider';
import Donate from '../Donate';
import SocialLinks from '../../containers/SocialLinks';
import SupportContact from '../SupportContact';

import './DonatePage.css';

const DonatePage = ({ companyInfo }) => (
  <div className="DonatePage py-4 py-lg-5">
    <Helmet title="Donate" />

    <AppFixedHeader />

    <Grid fluid>
      <Row>
        <Col xs={6}>
          <h1 className="title mb-4">Donate</h1>
        </Col>
        <Col xs={6} className="text-right">
          <SocialLinks className="ml-lg-5 mb-4" />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="mb-5 mt-2" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={8}>
          <p>
            Our policy innovations, rigorous research, and issue campaigns are
            made possible by the sustained engagement and generous contributions
            of our supporters. Third Way and Third Way Institute are connected
            non-profit, tax-exempt entities: Third Way is a 501(c)(4)
            organization, and Third Way Institute is a 501(c)(3). Third Way
            Institute helps fund the exempt work of Third Way. All online
            contributions go to Third Way Institute and are tax deductible.
          </p>
          <p>
            For more information on how to support the work of Third Way, please
            contact Emily Sternfeld, our Vice President of Development and
            External Affairs.
          </p>
        </Col>
        <Col xs={12} md={4} className="hidden visible-md">
          <SupportContact />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <Donate />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default DonatePage;

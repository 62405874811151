export default () => {
    return `
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17.5" cy="17.5" r="17.5" fill="#501E89"/>
            <path d="M17.4501 26C18.2201 26 18.9585 25.6941 19.5029 25.1497C20.0474 24.6052 20.3533 23.8668 20.3533 23.0968V21.0646C20.3533 20.2946 20.0474 19.5562 19.5029 19.0117C18.9585 18.4672 18.2201 18.1614 17.4501 18.1614C16.6801 18.1614 15.9417 18.4672 15.3972 19.0117C14.8527 19.5562 14.5469 20.2946 14.5469 21.0646V23.0968C14.5469 23.8668 14.8527 24.6052 15.3972 25.1497C15.9417 25.6941 16.6801 26 17.4501 26ZM16.2888 21.0646C16.2888 20.7566 16.4111 20.4612 16.6289 20.2434C16.8467 20.0256 17.1421 19.9033 17.4501 19.9033C17.7581 19.9033 18.0534 20.0256 18.2712 20.2434C18.489 20.4612 18.6114 20.7566 18.6114 21.0646V23.0968C18.6114 23.4048 18.489 23.7002 18.2712 23.918C18.0534 24.1357 17.7581 24.2581 17.4501 24.2581C17.1421 24.2581 16.8467 24.1357 16.6289 23.918C16.4111 23.7002 16.2888 23.4048 16.2888 23.0968V21.0646Z" fill="white"/>
            <path d="M10.8277 19.9033H11.0193C11.1979 19.903 11.3738 19.9468 11.5313 20.0309C11.6888 20.115 11.8231 20.2367 11.9222 20.3852C11.9857 20.4806 12.0673 20.5624 12.1625 20.6262C12.2576 20.69 12.3643 20.7344 12.4766 20.7569C12.5889 20.7794 12.7045 20.7796 12.8169 20.7574C12.9292 20.7352 13.0361 20.6911 13.1314 20.6277C13.2267 20.5642 13.3086 20.4825 13.3724 20.3874C13.4361 20.2923 13.4805 20.1856 13.503 20.0733C13.5256 19.961 13.5257 19.8454 13.5036 19.733C13.4814 19.6207 13.4373 19.5138 13.3738 19.4185C13.1153 19.0305 12.7647 18.7126 12.3533 18.4933C11.9419 18.2739 11.4826 18.1599 11.0164 18.1614H10.8248C10.0756 18.1629 9.35755 18.4614 8.82804 18.9915C8.29853 19.5215 8.00077 20.2399 8 20.9891V23.1752C8.00154 23.9244 8.30004 24.6425 8.83009 25.172C9.36014 25.7015 10.0785 25.9993 10.8277 26H11.0193C11.4848 26.0003 11.9431 25.8853 12.3534 25.6655C12.7637 25.4456 13.1133 25.1277 13.3709 24.74C13.4991 24.5475 13.5456 24.312 13.5001 24.0852C13.4547 23.8585 13.321 23.6591 13.1285 23.5309C12.936 23.4027 12.7005 23.3562 12.4737 23.4016C12.2469 23.4471 12.0475 23.5808 11.9193 23.7733C11.8206 23.9224 11.6864 24.0447 11.5289 24.1293C11.3713 24.2139 11.1953 24.2581 11.0164 24.2581H10.8248C10.5373 24.2573 10.2619 24.1426 10.0589 23.9391C9.85592 23.7355 9.74192 23.4598 9.74192 23.1723V20.9891C9.74192 20.7011 9.85632 20.425 10.0599 20.2213C10.2636 20.0177 10.5397 19.9033 10.8277 19.9033Z" fill="white"/>
            <path d="M8.53709 17.2004C8.6682 17.2635 8.81251 17.2944 8.95797 17.2903C9.10344 17.2863 9.24582 17.2475 9.37321 17.1772C9.52903 17.0875 9.65217 16.9505 9.72475 16.786C9.79732 16.6215 9.81554 16.4382 9.77676 16.2627C9.69161 15.7502 9.76347 15.2239 9.98289 14.753C10.2057 14.2902 10.556 13.9006 10.9926 13.6301C11.4293 13.3595 11.9341 13.2193 12.4477 13.2259H12.5203C12.7131 13.2256 12.9004 13.1613 13.0528 13.0431C13.2052 12.9249 13.314 12.7594 13.3622 12.5727C13.5681 11.7642 14.0375 11.0473 14.6963 10.5353C15.355 10.0233 16.1656 9.74534 16.9999 9.74534C17.8342 9.74534 18.6448 10.0233 19.3036 10.5353C19.9623 11.0473 20.4317 11.7642 20.6376 12.5727C20.6858 12.7594 20.7947 12.9249 20.947 13.0431C21.0994 13.1613 21.2867 13.2256 21.4796 13.2259H21.5521C22.0658 13.2193 22.5706 13.3595 23.0072 13.6301C23.4439 13.9006 23.7942 14.2902 24.017 14.753C24.2362 15.224 24.308 15.7502 24.2231 16.2627C24.1843 16.4382 24.2025 16.6215 24.2751 16.786C24.3477 16.9505 24.4708 17.0875 24.6266 17.1772C24.754 17.2475 24.8964 17.2863 25.0419 17.2903C25.1873 17.2944 25.3316 17.2635 25.4627 17.2004C25.5863 17.1394 25.6936 17.05 25.7759 16.9394C25.8581 16.8289 25.913 16.7005 25.936 16.5646C25.9783 16.325 25.9997 16.0821 25.9998 15.8388C25.9952 14.7957 25.6183 13.7886 24.9371 12.9987C24.2558 12.2088 23.3148 11.6882 22.2837 11.5305L22.1241 11.5072L22.0602 11.3563C21.6401 10.3613 20.936 9.51228 20.036 8.91533C19.136 8.31838 18.0799 8 16.9999 8C15.9199 8 14.8639 8.31838 13.9639 8.91533C13.0638 9.51228 12.3597 10.3613 11.9396 11.3563L11.8758 11.5072L11.7161 11.5305C10.685 11.6882 9.74407 12.2088 9.06278 12.9987C8.3815 13.7886 8.00461 14.7957 8 15.8388C8.00013 16.0821 8.0215 16.325 8.06387 16.5646C8.08684 16.7005 8.1417 16.8289 8.22397 16.9394C8.30623 17.05 8.41355 17.1394 8.53709 17.2004Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3028 20.4573C24.3028 20.4573 24.2019 20.4709 24.1587 20.4709C24.1155 20.4709 24.0722 20.4981 24.029 20.5253C23.9858 20.5525 23.957 20.5933 23.9281 20.6205C23.5246 21.2732 22.6456 21.4908 21.9539 21.1236C21.2766 20.7565 21.046 19.9269 21.4351 19.2742C21.6513 18.9206 21.9395 18.6078 22.2853 18.3631C22.6312 18.1183 23.0203 17.9415 23.4382 17.8327C23.8561 17.7511 24.3028 17.7239 24.7207 17.7783C25.153 17.8327 25.5565 17.9823 25.9312 18.1863C26.3058 18.4038 26.6229 18.6758 26.8679 19.0158C27.1272 19.3422 27.3002 19.7229 27.401 20.1173C27.5019 20.5117 27.5019 20.9332 27.4299 21.3276C27.3578 21.7356 27.1993 22.1163 26.9687 22.4563C26.9255 22.5243 26.8679 22.5923 26.8102 22.6467L25.7727 23.6802H26.0465C26.839 23.6802 27.4875 24.2922 27.4875 25.0401C27.4875 25.788 26.839 26.4 26.0465 26.4H22.4439C21.8674 26.4 21.3487 26.0736 21.1181 25.5841C20.8875 25.0809 21.0028 24.5098 21.3919 24.1154L24.5766 20.906L24.591 20.8517C24.591 20.8109 24.591 20.7565 24.591 20.7157C24.591 20.6749 24.5622 20.6341 24.5334 20.5933C24.5045 20.5525 24.4757 20.5253 24.4325 20.4981C24.3893 20.4709 24.346 20.4573 24.3028 20.4573Z" fill="white" stroke="#501E89" stroke-width="0.95"/>
        </svg>
    `
}
